import { signalStore, withComputed, withMethods, withState } from "@ngrx/signals";
import { withEntities } from "@ngrx/signals/entities";
import { computed, inject } from "@angular/core";
import { SelectOption } from "../../ui/form/search-select/SelectOption.class";
import { withGroupsApi } from "./groups-api.feature";
import { ScenarioGroupResponse } from "../../api/models/scenario-group-response";
import { ScriptsStore } from "../scripts/scripts.store";

type GroupState = {
    isLoaded: boolean;
};

const initialGroupState: GroupState = {
    isLoaded: false,
};

export const GroupsStore = signalStore(
    { providedIn: 'root', protectedState: false},
    withState(initialGroupState),
    withEntities<ScenarioGroupResponse>(),
    withGroupsApi(),
    withComputed((store) => ({
        uniqueGroups: computed(() => {
            let groups: ScenarioGroupResponse[] = store.entities();
            return groups.filter((group: ScenarioGroupResponse) => group.id === 'general');
        }),
        removableGroups: computed(() => {
            let groups: ScenarioGroupResponse[] = store.entities();
            return groups.filter((group: any) => group.id !== 'general');
        }),
        selectOptions: computed(() => {
            let groups: ScenarioGroupResponse[] = store.entities();
            return groups.map((group: ScenarioGroupResponse) => {
                return new SelectOption({ id: group.id, label: group.name, value: group.name });
            })
        })
    })),
    withMethods((store: any, scriptsStore = inject(ScriptsStore)) => ({
        removeGroups: (groups: ScenarioGroupResponse[]) => {
            let groupsMap: Map<string, ScenarioGroupResponse> = new Map(groups.map((group: ScenarioGroupResponse) => [group.id, group]));
            for (let script of scriptsStore.entities()) {
                if (script.scenario_group_id && groupsMap.has(script.scenario_group_id)) {
                    scriptsStore.patchPropertyById({ id: script.id, property: 'scenario_group_id', value: null});
                }
            }
            groups.forEach((group: ScenarioGroupResponse) => {
                store.removeGroupById(group.id);
            })
        }
    }))
)
