import { patchState, signalStoreFeature, withMethods } from "@ngrx/signals";
import { inject } from "@angular/core";
import { addEntity, removeEntity, setAllEntities, updateEntity } from "@ngrx/signals/entities";
import { rxMethod } from "@ngrx/signals/rxjs-interop";
import { concatMap, exhaustMap, filter, mergeMap, pipe, tap } from "rxjs";
import { ApiService } from "../../api/services/api.service";
import { ScenarioGroupResponse } from "../../api/models/scenario-group-response";
import { tapResponse } from "@ngrx/operators";
import { ScenarioGroupCreateRequest } from "../../api/models/scenario-group-create-request";

export function withGroupsApi() {
    return signalStoreFeature(
        withMethods((store: any, apiService: ApiService = inject(ApiService)) => ({
            loadGroups: rxMethod<void>(
                exhaustMap(() => {
                    return apiService.apiGroupsGet().pipe(
                        tap({
                            next: (groups: ScenarioGroupResponse[]) => {
                                groups.push({ client_id: '9b53891c-3a1b-4ef5-90e0-788288655ce2', id: 'general', name: 'Общее'})
                                patchState(store, setAllEntities(groups));
                                patchState(store, { isLoaded: true })
                            },
                            error: console.error,
                        })
                    );
                })
            ),
            loadGroupById: rxMethod<string>(
                pipe(filter((id: string) => !!id && !store.entityMap()[id]), concatMap((id) => {
                    return apiService.apiGroupsIdGet({id}).pipe(
                        tap({
                            next: (group: ScenarioGroupResponse) => {
                                patchState(store, addEntity(group));
                                patchState(store, { isLoaded: true })},
                            error: console.error,
                        })
                    );
                }))
            ),
            createGroup: rxMethod<ScenarioGroupCreateRequest>(
                pipe(filter((group) => !!group), concatMap((group) => {
                    return apiService.apiGroupsPost({ body: group }).pipe(
                        tapResponse({
                            next: (group: ScenarioGroupResponse) => patchState(store, addEntity(group)),
                            error: console.error
                        })
                    )
                }))
            ),

            removeGroupById: rxMethod<string>(
                pipe(filter((id: string) => !!id && store.entityMap()[id]), mergeMap((id) => {
                    return apiService.apiGroupsIdDelete({ id }).pipe(
                        tapResponse({
                            next: () => patchState(store, removeEntity(id)),
                            error: console.error
                        })
                    )
                }))
            ),
            patchGroupNameById: rxMethod<{ id: string, name: string }>(
                pipe(filter((info) => !!info.id && store.entityMap()[info.id]), mergeMap((info) => {
                    return apiService.apiGroupsIdPatch({
                        id: info.id,
                        body: {
                            name: info.name
                        }
                    }).pipe(
                        tapResponse({
                            next: (group: ScenarioGroupResponse) => patchState(store, updateEntity({ id: info.id, changes: { ...group }})),
                            error: console.error
                        })
                    )
                }))
            )
        })),
    )
}

